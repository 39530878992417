import React  from 'react'
import Home from './components/Home'
import SignUp from './components/SignUp'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

const signUpInputs = [
  {
    "label": "Prénoms",
    "name": "firstName"
  },
  {
    "label": "Nom",
    "name": "lastName"
  },
  {
    "label": "Adresse e-mail",
    "name": "email"
  }
]

const App= () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/subscribe">
          <SignUp txtInputs={signUpInputs} />
        </Route>
        <Route path="*">
          <Home />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
