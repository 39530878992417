import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Copyright from './Copyright';
import Summary from './Summary';
import useStyles from './useStyles';
import Header from './Header.js'
import * as Yup from 'yup';
import { Formik, useField } from 'formik';
import axios from 'axios';

const ALREADY_EXISTING_USER = 422;

const MyTextInput = ({ label, ...props }) => {

  const [field, meta] = useField(props);
  return (
    <Grid item xs={12}>
      <TextField
        required
        fullWidth
        variant="outlined"
        label={label}
        {...field}
        {...props}
        error={meta.error && meta.touched ? true : false}
      />
    </Grid>
  );
};

const postVolunteer = (props) => {
  console.log(JSON.stringify(props, null, 2));
  console.log(process.env);

  //return axios.post("https://7ikt8rmqqb.execute-api.eu-west-3.amazonaws.com/Prod/volunteers/", {
  return axios.post(`${process.env.REACT_APP_API_BASE_URL}/volunteers/`, {
    ...props
  })
    .then(response => {
      if (response.status === 200) {
        return true
      }
    })
    .catch(error => {
      if (error.response != null){
        if (error.response.status === ALREADY_EXISTING_USER) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    });
}


// why those two ways for creating a component ? what is the difference ?
// export default function SignUp() {

const SignUp = ({ txtInputs }) => {
  const classes = useStyles();

  const [subscribed, setSubscribed] = useState(false);

  if (!subscribed) {
  return (
    <Formik
      initialValues={{
        firstName:'',
        lastName:'',
        email:'',
      }}
      validationSchema={Yup.object({
        firstName: Yup.string().required('Champ requis'),
        lastName: Yup.string().required('Champ requis'),
        email: Yup.string()
          .email('Adresse mail invalide')
          .required('Champ requis'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        if (!subscribed) {
          setTimeout(() => {
            setSubmitting(false);
            postVolunteer(values).then(result => {
              console.log(`result: ${result}`)
              setSubscribed(result)
            })
          }, 400);
        }
      }}
    >
      {formik => (
        <Container maxWidth="md">
          <Header title="Soutenons nos aînés" />
          <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5" gutterBottom>
              Inscription
            </Typography>

            <Typography component="h1" variant="body2" align="center">
              Pour des sessions telephoniques avec des établisssemnts
            </Typography>

            <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                {txtInputs.map(txtInput => <MyTextInput {...txtInput} />)}
              </Grid>
              <FormHelperText>En soumettant ce formulaire, je consens à fournir mes données.</FormHelperText>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                M'enregistrer
              </Button>
            </form>
          </div>
          <Box mt={5}>
            <Divider />
            <Copyright />
          </Box>
          </Container>
        </Container>
      )}
    </Formik>
  )} else {
    return (
      <Summary />
    )
  }
};

export default SignUp;
