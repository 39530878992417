import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Markdown from './Markdown'
import Header from './Header'
import Copyright from './Copyright';


const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
   markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
}));

const leetchiUrl = "https://www.leetchi.com/c/soutenons-nos-aines"

const Home = () => {

  const classes = useStyles();

  const content = `
Depuis le début de l'épidemie du Covid-19, les résidents des EHPAD de France
sont pour la plupart confinés dans leur chambre. Cela fait plus d'un mois qu'ils
n’ont de contact qu’avec le personnel soignant et cela se fait avec le masque,
les gants, ce qui ne favorise pas la convivialité.

Cette cagnotte permettra l’achat de tablettes pour les mettre à disposition
aux EHPAD qui en ont besoin.

Grâce à ces tablettes les résidents pourront être plus connectés avec leurs proches.
Le but c’est que nous puissions tous être connectés dans ces moments difficiles.

Deux modes de participation sont possible : soit via
[la cagnotte en ligne Leetchi](${leetchiUrl}), soit à travers des sessions
telephoniques avec des EHPADs.
`

  return (
    <Container maxWidth="md">
      <Header title="Soutenons nos aînés" />
      <Grid container spacing={2} className={classes.mainGrid}>
        <Grid item xs={12} >
          <Typography variant="h6" gutterBottom>
            Pourquoi cette initiative ?
          </Typography>
          <Divider />
          <Markdown className={classes.markdown} key="main">
            {content}
          </Markdown>
        </Grid>
        <Grid container spacing={2} align="left">
          <Grid item xs={8}>
            <Button variant="contained" href={leetchiUrl}>
              Cagnotte leetchi
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Button color="primary" variant="contained" href="/subscribe">
              session telephonique
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={5}>
      <Divider />
        <Copyright />
      </Box>
    </Container>
  )

}

export default Home
