import React from 'react';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Grid from '@material-ui/core/Grid';
import useStyles from './useStyles';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';

const Summary = () => {

  const classes = useStyles();
  return (

        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '50vh' }}
          >
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <FavoriteIcon />
            </Avatar>
            <Typography component="h1" variant="h5" gutterBottom>
              Merci pour votre participation
            </Typography>
            <Typography variant="caption" display="block" align="center" gutterBottom>
              Nous vous contacterons bientôt !
            </Typography>
          </div>
          </Grid>
        </Container>
  );
}

export default Summary;
